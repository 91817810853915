import moment from "moment";
import { CashRegister } from "./cash-register";
import { SalesOrderItem } from "./sales-order-item";
import { SalesOrder } from "./sales-order";
import { maxBy, orderBy, sumBy, uniq } from "lodash";

export class Promotion {

  id?: string;
  name!: string;
  description?: string;
  startDate!: Date;
  endDate!: Date;
  createdDate?: Date;
  promotionRule: PromotionRule = new PromotionRule();

  get promotionType(): PromotionType {
    const promotionTypeMap: Record<keyof PromotionRule, PromotionType> = {
      productDiscount: PromotionType.ProductDiscount,
      orderDiscount: PromotionType.OrderDiscount,
      buyXGetY: PromotionType.BuyXGetY
    };

    const promotionRule = this.promotionRule;
    const promotionType = Object.keys(promotionTypeMap).find(
      (key): key is keyof PromotionRule => promotionRule[key as keyof PromotionRule] != null
    );

    return promotionTypeMap[promotionType!];
  }

  public static clone(promotion?: Promotion): Promotion | undefined {
    if (!promotion) return undefined;
    const newPromotion = Object.assign(new Promotion(), promotion);
    return newPromotion;
  }

  public static isLineItemPromotionValid(saleOrderItem: SalesOrderItem, promotion: Promotion, salesOrder: SalesOrder) {
    if (!promotion)
      return false;

    // if order discount then it is not valid
    if (promotion.promotionRule.orderDiscount) return false;

    if (promotion.promotionRule.productDiscount)
      return ProductDiscountPromotionRule.canApply(promotion.promotionRule.productDiscount, saleOrderItem, salesOrder.cashRegister);

    if (promotion.promotionRule.buyXGetY)
      return BuyXGetYPromotionRule.canApply(promotion.promotionRule.buyXGetY, saleOrderItem, salesOrder);

    return false;
  }

  public static isOrderPromotionValid(promotion?: Promotion, cashRegister?: CashRegister) {
    if (!promotion)
      return false;

    // Assure target type is Order
    if (!promotion.promotionRule.orderDiscount)
      return false;

    return OrderDiscountPromotionRule.canApply(promotion.promotionRule.orderDiscount, cashRegister);
  }

  static applyToOrder(salesOrder: SalesOrder, promotion?: Promotion) {
    if (!Promotion.isOrderPromotionValid(promotion, salesOrder.cashRegister)) {
      salesOrder.discount_rate = 0;
      salesOrder.discountValue = 0;
      return undefined;
    }

    const orderDiscountRule = promotion!.promotionRule.orderDiscount!;
    if (orderDiscountRule.valueType === DiscountValueType.FixedAmount) {
      salesOrder.discount_rate = 0;
      salesOrder.discountValue = orderDiscountRule.value;
    } else if (orderDiscountRule.valueType === DiscountValueType.Percentage) {
      salesOrder.discountValue = 0;
      salesOrder.discount_rate = orderDiscountRule.value;
    }

    return promotion;
  }

  static applyBestPromotionToOrder(salesOrder: SalesOrder, promotions: Promotion[]): Promotion | undefined {
    const matchedPromos = promotions
      .filter(x =>
        Promotion.isOrderPromotionValid(x, salesOrder.cashRegister) &&
        x.promotionRule.orderDiscount!.entitledCustomerTargetSelection === PromotionTargetSelection.All
      );

    const bestPromotion = maxBy(matchedPromos, x => x.createdDate);
    if (!bestPromotion) return undefined;

    return Promotion.applyToOrder(salesOrder, bestPromotion);
  }


  static applyPromotionsToOrderItems(salesOrder: SalesOrder, promotions: Promotion[]): void {
    const buyXGetYPromotions = promotions.filter(x => !!x.promotionRule.buyXGetY);
    const productPromotions = promotions.filter(x => !!x.promotionRule.productDiscount);

    // Reset all promotions and merge benefit items back
    Promotion.resetPromotions(salesOrder);

    // Apply BuyXGetY Promotions to remaining items
    for (const item of salesOrder.salesOrder_details) {
      BuyXGetYPromotionRule.applyToItem(item, buyXGetYPromotions, salesOrder);
    }

    // Apply Product Discounts to remaining items
    for (const item of salesOrder.salesOrder_details) {
      if (!item.promotion && !item.isBuyXGetYPrerequisite && !item.isBenefit) {
        Promotion.applyProductDiscount(item, productPromotions, salesOrder);
      }
    }
  }

  private static resetPromotions(salesOrder: SalesOrder): void {
    for (const item of salesOrder.salesOrder_details) {
      if (item.promotion || item.isBenefit || item.isBuyXGetYPrerequisite) {
        item.promotion = undefined;
        item.item_discount = 0;
        item.item_discount_type = DiscountValueType.FixedAmount;
        item.isBenefit = false;
        item.isBuyXGetYPrerequisite = false;
      }
    }
  }

  private static applyProductDiscount(item: SalesOrderItem, productPromotions: Promotion[], salesOrder: SalesOrder): void {
    const applicablePromotions = productPromotions
      .filter(x => !!x.promotionRule.productDiscount)
      .filter(x => Promotion.isLineItemPromotionValid(item, x, salesOrder));
    const bestPromotion = maxBy(applicablePromotions, x => x.createdDate);

    if (bestPromotion) {
      const rule = bestPromotion.promotionRule.productDiscount!;
      item.item_discount_type = rule.valueType;
      item.item_discount = rule.value
      item.promotion = bestPromotion.name;
    }
  }
}

export class PromotionRule {
  productDiscount?: ProductDiscountPromotionRule;
  orderDiscount?: OrderDiscountPromotionRule;
  buyXGetY?: BuyXGetYPromotionRule;
}

export class ProductDiscountPromotionRule {
  entitledCashRegisterTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledCashRegisterIds?: string[];
  entitledProductTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledProductSelectionMode?: PromotionSelectionMode;
  entitledProductIds?: string[];
  valueType: DiscountValueType = DiscountValueType.Percentage;
  value!: number;
  period: PromotionPeriod = new PromotionPeriod();

  public static canApply(rule: ProductDiscountPromotionRule, saleOrderItem: SalesOrderItem, cashRegister?: CashRegister) {
    // Check if stock item
    if (!saleOrderItem.stock_item_id) return false;

    // Check day selection
    if (!PromotionPeriod.isCurrent(rule.period)) return false;

    // Check cash register
    if (
      rule.entitledCashRegisterTargetSelection === PromotionTargetSelection.Entitled
      && (!cashRegister || !rule.entitledCashRegisterIds?.includes(cashRegister.id))
    ) return false;

    // Check item
    if (rule.entitledProductTargetSelection === PromotionTargetSelection.Entitled) {
      const includeProducts = rule.entitledProductSelectionMode === PromotionSelectionMode.Include;
      const excludeProducts = rule.entitledProductSelectionMode === PromotionSelectionMode.Exclude;

      const itemId = saleOrderItem.stock_item_id.toString();
      const isProductIncluded = includeProducts && rule.entitledProductIds?.includes(itemId);
      const isProductExcluded = excludeProducts && !rule.entitledProductIds?.includes(itemId);

      if (!(isProductIncluded || isProductExcluded)) return false;
    }

    return true;
  }
}

export class OrderDiscountPromotionRule {
  entitledCashRegisterTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledCashRegisterIds?: string[];
  entitledCustomerTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  promoCode?: string;
  valueType: DiscountValueType = DiscountValueType.Percentage;
  value!: number;
  period: PromotionPeriod = new PromotionPeriod();

  public static canApply(rule: OrderDiscountPromotionRule, cashRegister?: CashRegister) {
    return PromotionPeriod.isCurrent(rule.period) &&
      (rule.entitledCashRegisterTargetSelection !== PromotionTargetSelection.Entitled ||
        cashRegister && rule.entitledCashRegisterIds?.includes(cashRegister.id));
  }
}

export class BuyXGetYPromotionRule {
  entitledCashRegisterTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledCashRegisterIds?: string[];
  period: PromotionPeriod = new PromotionPeriod();
  prerequisiteQuantity!: number;
  prerequisiteProductTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  prerequisiteProductSelectionMode?: PromotionSelectionMode;
  prerequisiteProductIds?: string[];
  entitledProductTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
  entitledProductSelectionMode?: PromotionSelectionMode;
  entitledProductIds?: string[];
  benefitType: BuyXGetYBenefitType = BuyXGetYBenefitType.FreeProduct;
  entitledQuantity!: number;
  discountValue!: number;

  public static canApply(rule: BuyXGetYPromotionRule, saleOrderItem: SalesOrderItem, salesOrder: SalesOrder) {
    // Check if stock item
    if (!saleOrderItem.stock_item_id) return false;

    // Check day selection
    if (!PromotionPeriod.isCurrent(rule.period)) return false;

    // Check cash register
    if (
      rule.entitledCashRegisterTargetSelection === PromotionTargetSelection.Entitled
      && (!salesOrder.cashRegister || !rule.entitledCashRegisterIds?.includes(salesOrder.cashRegister.id))
    ) return false;

    // Check item is entitled
    if (rule.entitledProductTargetSelection === PromotionTargetSelection.Entitled) {
      const includeProducts = rule.entitledProductSelectionMode === PromotionSelectionMode.Include;
      const excludeProducts = rule.entitledProductSelectionMode === PromotionSelectionMode.Exclude;

      const itemId = saleOrderItem.stock_item_id!.toString();
      const isProductIncluded = includeProducts && rule.entitledProductIds?.includes(itemId);
      const isProductExcluded = excludeProducts && rule.entitledProductIds?.includes(itemId);

      if (isProductExcluded || !isProductIncluded) return false;
    }

    // check prerequisite
    if (rule.prerequisiteQuantity > 0) {
      const isEntitled = rule.prerequisiteProductTargetSelection === PromotionTargetSelection.Entitled;
      const includeProducts = isEntitled && rule.prerequisiteProductSelectionMode === PromotionSelectionMode.Include;
      const excludeProducts = isEntitled && rule.prerequisiteProductSelectionMode === PromotionSelectionMode.Exclude;
      const orderQuantity = salesOrder.salesOrder_details.reduce((acc, item) => {
        const productId = item.stock_item_id!.toString();
        const isProductIncluded = includeProducts && rule.prerequisiteProductIds?.includes(productId);
        const isProductExcluded = excludeProducts && rule.prerequisiteProductIds?.includes(productId);
        const shouldInclude = !isEntitled || isProductIncluded || !isProductExcluded;
        return shouldInclude ? acc + item.quantity : acc;
      }, 0);

      if (orderQuantity <= rule.prerequisiteQuantity) return false;
    }

    return true;
  }

  static applyToItem(item: SalesOrderItem, buyXGetYPromotions: Promotion[], salesOrder: SalesOrder) {
    if (item.isBenefit || item.isBuyXGetYPrerequisite || item.promotion) return;
    const eligiblePromotions = buyXGetYPromotions.filter(x => !!x.promotionRule.buyXGetY)
      .filter(x => BuyXGetYPromotionRule.canApply(x.promotionRule.buyXGetY!, item, salesOrder));
    const bestPromotion = maxBy(eligiblePromotions, x => x.createdDate);
    if (!bestPromotion) return;

    const rule = bestPromotion.promotionRule.buyXGetY!;
    const promotionPrerequisiteQuantity = rule.prerequisiteQuantity;
    const promotionEntitledQuantity = rule.entitledQuantity;
    const discountValue = rule.benefitType === BuyXGetYBenefitType.FreeProduct ? 100 : rule.discountValue;

    const isEntitled = (x: SalesOrderItem) => {
      return rule.entitledProductTargetSelection === PromotionTargetSelection.All
        || (
          rule.entitledProductSelectionMode === PromotionSelectionMode.Include
          && rule.entitledProductIds?.includes(x.stock_item_id!.toString())
        )
        || (
          rule.entitledProductSelectionMode === PromotionSelectionMode.Exclude
          && !rule.entitledProductIds?.includes(x.stock_item_id!.toString())
        );
    };
    const isPrerequisite = (x: SalesOrderItem) => {
      return rule.prerequisiteProductTargetSelection === PromotionTargetSelection.All
        || (
          rule.prerequisiteProductSelectionMode === PromotionSelectionMode.Include
          && rule.prerequisiteProductIds?.includes(x.stock_item_id!.toString())
        )
        || (
          rule.prerequisiteProductSelectionMode === PromotionSelectionMode.Exclude
          && !rule.prerequisiteProductIds?.includes(x.stock_item_id!.toString())
        );
    };


    const entitledItems = salesOrder.salesOrder_details
      .filter(x => isEntitled(x) && !x.isBenefit && !x.isBuyXGetYPrerequisite);

    const prerequisiteItems = salesOrder.salesOrder_details
      .filter(x => isPrerequisite(x) && !x.isBenefit && !x.isBuyXGetYPrerequisite);

    const totalPrerequisiteQuantity = sumBy(prerequisiteItems, x => x.quantity);
    const totalEntitledQuantity = sumBy(entitledItems, x => x.quantity);
    const totalItemsQuantity = sumBy(uniq(prerequisiteItems.concat(entitledItems)), x => x.quantity);

    const applicableSets = Math.floor(totalItemsQuantity / (promotionPrerequisiteQuantity + promotionEntitledQuantity));
    const availableSets = Math.min(
      applicableSets * promotionEntitledQuantity,
      Math.floor(totalPrerequisiteQuantity / promotionPrerequisiteQuantity),
      Math.floor(totalEntitledQuantity / promotionEntitledQuantity)
    )
    const totalDiscountedQuantity = availableSets * promotionEntitledQuantity;

    if (totalDiscountedQuantity > 0) {
      // Process prerequisite items
      let remainingPrerequisiteQuantity = availableSets * promotionPrerequisiteQuantity;

      for (const prereqItem of orderBy(prerequisiteItems, ['price', 'serial'], 'desc')) {
        if (remainingPrerequisiteQuantity <= 0) break;
        const quantityToMark = Math.min(prereqItem.quantity, remainingPrerequisiteQuantity);
        const existingPrerequisite = salesOrder.salesOrder_details.find(x => x.stock_item_id === prereqItem.stock_item_id && x.isBuyXGetYPrerequisite);

        if(existingPrerequisite){
          existingPrerequisite.quantity += quantityToMark;
          prereqItem.quantity -= quantityToMark;
          if(prereqItem.quantity <= 0){
            salesOrder.salesOrder_details.splice(salesOrder.salesOrder_details.indexOf(prereqItem), 1);
          }
        }
        else if(prereqItem.quantity <= quantityToMark){
          prereqItem.isBuyXGetYPrerequisite = true;
        } else {
          prereqItem.quantity -= quantityToMark;
          const newLine = SalesOrderItem.clone(prereqItem);
          newLine.quantity = quantityToMark;
          newLine.isBuyXGetYPrerequisite = true;
          salesOrder.salesOrder_details.splice(salesOrder.salesOrder_details.indexOf(prereqItem), 0, newLine);
        }

        remainingPrerequisiteQuantity -= quantityToMark;
      }

      // Process entitled items for benefits
      let remainingDiscountedQuantity = totalDiscountedQuantity;

      for (const entitledItem of orderBy(entitledItems, ['price', 'serial'], ['asc', 'desc'])) {
        if (remainingDiscountedQuantity <= 0) break;
        const quantityToDiscount = Math.min(entitledItem.quantity, remainingDiscountedQuantity);
        const existingBenefit = salesOrder.salesOrder_details.find(x => x.stock_item_id === entitledItem.stock_item_id && x.isBenefit);

        if(existingBenefit){
          existingBenefit.quantity += quantityToDiscount;
          entitledItem.quantity -= quantityToDiscount;
          if(entitledItem.quantity <= 0){
            salesOrder.salesOrder_details.splice(salesOrder.salesOrder_details.indexOf(entitledItem), 1);
          }
        }
        else if (entitledItem.quantity <= quantityToDiscount) {
          entitledItem.isBenefit = true;
          entitledItem.promotion = bestPromotion.name;
          entitledItem.item_discount_type = DiscountValueType.Percentage;
          entitledItem.item_discount = discountValue;
        }
        else{
          const newLine = SalesOrderItem.clone(entitledItem);
          entitledItem.quantity -= quantityToDiscount;
          newLine.quantity = quantityToDiscount;
          newLine.isBenefit = true;
          newLine.promotion = bestPromotion.name;
          newLine.item_discount_type = DiscountValueType.Percentage;
          newLine.item_discount = discountValue;
          salesOrder.salesOrder_details.splice(salesOrder.salesOrder_details.indexOf(entitledItem), 0, newLine);
        }

        remainingDiscountedQuantity -= quantityToDiscount;
      }

      // Reset serials after adding new items
      salesOrder.resetOrderItemsSerials();
    }
  }
}

export class PromotionPeriod {
  from!: Date;
  to!: Date;
  daySelection: PromotionDaySelection = PromotionDaySelection.All;
  days?: string[];

  static isCurrent(period: PromotionPeriod) {
    return period.daySelection === PromotionDaySelection.All
      || period.days?.includes(DayOfWeek[moment().day()].toString());
  }
}

export enum PromotionTargetSelection {
  All = 0,
  Entitled = 1
}

export enum PromotionSelectionMode {
  Include = 0,
  Exclude = 1
}

export enum DiscountValueType {
  FixedAmount = 0,
  Percentage = 1
}

export enum PromotionDaySelection {
  All = 0,
  Prerequisite = 1
}

export enum BuyXGetYBenefitType {
  FreeProduct = 0,
  Discount = 1
}

export enum PromotionStatus {
  Past = 0,
  Current = 1,
  Upcoming = 2
}

export enum DayOfWeek {
  SUN = 0,
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6
}

export enum PromotionType {
  ProductDiscount = 0,
  OrderDiscount = 1,
  BuyXGetY = 2
}
