<div class="filter__dropdown filter__dropdown--view">

  <button mat-button [matMenuTriggerFor]="topMenu" [disabled]="!isLoaded" class="filter__dropdown-button"
    [matTooltipDisabled]="!isNameTooLong" [matTooltip]="selectedView?.name"
    matTooltipPosition="above">
    <span> {{isLoaded ? ((selectedView?.name === "Default View" ? ("gridFilter.default_view" |translate) :
      selectedView?.name) | slice:0:15 || ('gridFilter.default_view' | translate)) : ('gridFilter.loading' |
      translate)}} {{isNameTooLong ? '...' : ''}}</span>
    <icon name="arrow_drop_down" class="margin-start-s"></icon>
  </button>
  <mat-menu #topMenu="matMenu" class="filter-menu filter-menu--with-overlay no-margin">
    <ng-container *ngFor="let item of viewsList">
      <button mat-menu-item (click)="onClickItem(item)" [matMenuTriggerFor]="submenu"
        [ngClass]="{'mat-menu-item--selected': item.id === selectedViewValue}">
        <span class="filter-menu-item-text">{{item.name === "Default View" ? ("gridFilter.default_view" |translate) :
          item.name}}</span>
      </button>
      <mat-menu #submenu="matMenu" class="filter-menu">
        <button mat-menu-item (click)="editViewName(item)">
          <icon name="edit" class="padding-end-s"></icon>
          {{'gridFilter.renameView' | translate}}
        </button>
        <ng-container>
          <mat-divider></mat-divider>
          <button mat-menu-item class="menu-item-error" (click)="deleteAgGridFilterView(item.id)">
            <icon name="delete" class="menu-item-error--icon padding-end-s"></icon>
            {{'gridFilter.deleteview' | translate}}
          </button>
        </ng-container>

      </mat-menu>
    </ng-container>
    <button mat-menu-item class="d-flex justify-content-between" (click)="editId = null;viewName = '';createNew()">
      {{'gridFilter.createView' | translate}}
      <icon name="add" class="filter-menu__icon"></icon>
    </button>
  </mat-menu>
  <div class="filter-menu filter__dropdown--view--container m-0 " *ngIf="isCreate">
    <span class="filter-menu--with-overlay"></span>
    <div class="filter__dropdown filter__dropdown--view--popup">
      <form>
        <mat-form-field class="filter-view-input--container margin-bottom-none" appearance="outline"
          [ngClass]="{'filter-view-input--container--focus': isFocusInput}">
          <input matInput [autofocus]="true" required (focus)="isFocusInput = true" (blur)="isFocusInput = false"
            placeholder="{{'gridFilter.enterViewName' | translate}}" name="viewName" class="filter-view-input"
            [(ngModel)]="viewName" type="text" />
        </mat-form-field>
        <div class="d-flex justify-content-center align-items-center margin-top-base">
          <button mat-stroked-button *ngIf="!editId" color="primary" type="submit" class="margin-end-s"
            [disabled]="isSaving" (click)="createView()">{{(isSaving ? 'gridFilter.saving':'gridFilter.saveView') |
            translate}}</button>
          <button mat-stroked-button *ngIf="editId" color="primary" type="submit" class="margin-end-s"
            [disabled]="isSaving" (click)="renameView(viewName)">{{(isSaving ?
            'gridFilter.updating':'gridFilter.updateView') | translate}}</button>

          <button mat-button color="warn" type="button" (click)="isCreate = false"
            [disabled]="isSaving">{{'gridFilter.cancel' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>