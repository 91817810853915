import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/material-module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { RemoveFeaturesDirective } from './directives/remove-feature.directive';
import { DisableFeaturesDirective } from './directives/disable-feature.directive';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { QuantityFormatter } from './pipes/quantity-formatter.pipe';
import { CurrencyFormatter } from './pipes/currency-formatter.pipe';
import { DateFormatter } from './pipes/date-formatter.pipe';
import { MoneyRounding } from './pipes/money-rounding.pipe';
import { ToNumberPipe } from './pipes/string-to-number.pipe';
import { MatPaginatorIntlCro } from './services/mat-paginator-Intl.provider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { DragDirective } from './directives/drag-drop.directive';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { NumberFieldDirective } from './directives/number-field.directive';
import { AuditLogComponent } from './components/audit-log/audit-log.component';
import { AuditLogValueFormatter } from './pipes/audit-log-value-formatter.pipe';
import { LookupChipsComponent } from './components/lookup-chips/lookup-chips.component';
import { IntlTelInputComponent } from './components/intl-tel-input/intl-tel-input.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LongPressDirective } from './directives/long-press.directive';
import { HasPermissionPipe } from './pipes/has-permission.pipe';
import { HasPermissionsPipe } from './pipes/has-permissions.pipe';
import { IsInRolePipe } from './pipes/is-in-role.pipe';
import { DescriptiveErrorPipe } from './pipes/descriptive-error.pipe';
import { AgGridIdsDirective } from './directives/ag-grid-ids.directive';
import { InterceptorsModule } from '../core/interceptors/interceptors.module';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { ScopedDialogComponent } from './components/scoped-dialog/scoped-dialog.component';
import { LogoComponent } from '@core/components/logo/logo.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AgNoRowsOverlayComponent } from './components/ag-no-rows-overlay/ag-no-rows-overlay.component';
import { AgLoadingOverlayComponent } from './components/ag-loading-overlay/ag-loading-overlay.component';
import { AutoCompleteListBoxComponent } from './components/auto-complete-list-box/auto-complete-list-box.component';
import { IconComponent } from './components/icon/icon.component';
import { DomSanitizerPipe } from './pipes/dom-sanitizer.pipe';
import { CodeConfigurationComponent } from './components/code-configuration/code-configuration.component';
import { CurrencyContainerComponent } from './components/currency-container/currency-container.component';
import { PositiveNumberDirective } from './directives/positive-number.directive';
import { AgoDatePipe } from './pipes/ago-date.pipe';
import { LanguageDirectionDirective } from './directives/language-direction.directive';
import { OrderByPipe } from './pipes/order-by.pipe';
import { CurrencyFormatterV3Pipe } from './pipes/currency-formatter-v3.pipe';
import { QuantityFormatterV3Pipe } from './pipes/quantity-formatter-v3.pipe';
import { ObjectToArrayPipe } from './pipes/object-to-array.pipe';
import { MultiplyPipe } from './pipes/multiply.pipe';
import { AgGridModule } from 'ag-grid-angular';
import { BarcodeComponent } from './components/barcode/barcode.component';
import { ScanDetectDirective } from './directives/scan-detect.directive';
import { AgUserEmailChipRendererComponent } from './components/ag-user-email-chip-renderer/ag-user-email-chip-renderer.component';
import { WorkflowAuditLogValueFormatter } from './pipes/workflow-audit-log-value-formatter';
import { RowHighlightOnMenuOpenDirective } from './directives/row-highlight-on-menu-open.directive';
import { Capitalize } from './pipes/capitalize.pipe';
import { FromNowPipe } from './pipes/from-now.pipe';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { FindItemInArrayPipe } from './pipes/find-item-in-array.pipe';
import { LandingListItemComponent } from './components/landing-list-item/landing-list-item.component';
import { ModulesLandingControllerComponent } from './components/modules-landing-controller/modules-landing-controller.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoadingSnackbarComponent } from './components/loading-snackbar/loading-snackbar.component';
import { NoteAlertComponent } from './components/note-alert/note-alert.component';
import { AgGridCommonFilterViewComponent } from './components/ag-grid-common-filter-view/ag-grid-common-filter-view.component';
import { AgGridHeaderComponent } from './components/ag-grid-header/ag-grid-header.component';
import { AgGridCommonFieldsComponent } from './components/ag-grid-common-fields/ag-grid-common-fields.component';
import { AgGridCommonSortComponent } from './components/ag-grid-common-sort/ag-grid-common-sort.component';
import { AgGridCommonFilterComponent } from './components/ag-grid-common-filter/ag-grid-common-filter.component';
import { AgFilterColumnsPipe } from './pipes/ag-filter-columns.pipe';
import { AgFilterColumnOperatorsPipe } from './pipes/ag-filter-column-operators.pipe';
import { GetAgFilterColumnPipe } from './pipes/get-ag-filter-column.pipe';

@NgModule({
  declarations: [
    LoadingComponent,
    ConfirmationDialogComponent,
    ToggleButtonComponent,
    AutofocusDirective,
    RemoveFeaturesDirective,
    DisableFeaturesDirective,
    NumberFieldDirective,
    LongPressDirective,
    CurrencyFormatter,
    QuantityFormatter,
    DateFormatter,
    MoneyRounding,
    ToNumberPipe,
    AuditLogValueFormatter,
    WorkflowAuditLogValueFormatter,
    UploadFileComponent,
    DragDirective,
    AutoCompleteComponent,
    AuditLogComponent,
    LookupChipsComponent,
    IntlTelInputComponent,
    HasPermissionPipe,
    HasPermissionsPipe,
    IsInRolePipe,
    DescriptiveErrorPipe,
    AgGridIdsDirective,
    ScopedDialogComponent,
    IconComponent,
    LogoComponent,
    SpinnerComponent,
    AgNoRowsOverlayComponent,
    AgLoadingOverlayComponent,
    AutoCompleteListBoxComponent,
    DomSanitizerPipe,
    CodeConfigurationComponent,
    CurrencyContainerComponent,
    PositiveNumberDirective,
    AgoDatePipe,
    LanguageDirectionDirective,
    OrderByPipe,
    CurrencyFormatterV3Pipe,
    QuantityFormatterV3Pipe,
    ObjectToArrayPipe,
    MultiplyPipe,
    BarcodeComponent,
    ScanDetectDirective,
    AgUserEmailChipRendererComponent,
    RowHighlightOnMenuOpenDirective,
    Capitalize,
    FromNowPipe,
    FilterArrayPipe,
    FindItemInArrayPipe,
    LandingListItemComponent,
    ModulesLandingControllerComponent,
    LandingPageComponent,
    LoadingSnackbarComponent,
    NoteAlertComponent,
    AgGridHeaderComponent,
    AgGridCommonFilterViewComponent,
    AgGridCommonFilterComponent,
    AgGridCommonSortComponent,
    AgGridCommonFieldsComponent,
    AgFilterColumnsPipe,
    AgFilterColumnOperatorsPipe,
    GetAgFilterColumnPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
    TranslateModule,
    NgbModule,
    InterceptorsModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    AutofocusDirective,
    RemoveFeaturesDirective,
    DisableFeaturesDirective,
    NumberFieldDirective,
    LongPressDirective,
    CurrencyFormatter,
    QuantityFormatter,
    DateFormatter,
    MoneyRounding,
    ToNumberPipe,
    AuditLogValueFormatter,
    WorkflowAuditLogValueFormatter,
    ToggleButtonComponent,
    AgGridModule,
    DragDirective,
    LoadingComponent,
    UploadFileComponent,
    AutoCompleteComponent,
    AuditLogComponent,
    LookupChipsComponent,
    IntlTelInputComponent,
    HasPermissionPipe,
    HasPermissionsPipe,
    IsInRolePipe,
    DescriptiveErrorPipe,
    AgGridIdsDirective,
    ScopedDialogComponent,
    IconComponent,
    LogoComponent,
    AutoCompleteListBoxComponent,
    DomSanitizerPipe,
    CodeConfigurationComponent,
    CurrencyContainerComponent,
    PositiveNumberDirective,
    AgoDatePipe,
    LanguageDirectionDirective,
    OrderByPipe,
    CurrencyFormatterV3Pipe,
    QuantityFormatterV3Pipe,
    ObjectToArrayPipe,
    MultiplyPipe,
    BarcodeComponent,
    ScanDetectDirective,
    AgUserEmailChipRendererComponent,
    RowHighlightOnMenuOpenDirective,
    Capitalize,
    LandingListItemComponent,
    LandingPageComponent,
    LoadingSnackbarComponent,
    NoteAlertComponent,
    AgGridHeaderComponent,
    AgGridCommonFilterViewComponent,
    AgGridCommonFilterComponent,
    AgGridCommonSortComponent,
    AgGridCommonFieldsComponent,
    AgFilterColumnsPipe,
    AgFilterColumnOperatorsPipe,
    GetAgFilterColumnPipe,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const service = new MatPaginatorIntlCro();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    },
    HasPermissionsPipe,
    IsInRolePipe,
    FromNowPipe,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class SharedModule { }
