/**
 * @component AgGridCommonSortComponent
 *
 * @description
 * This component handles the sorting functionality in AG Grid.
 * It allows users to add, remove, and reorder sort conditions, 
 * and applies them to the grid. Each condition corresponds to a column 
 * in the grid and a sort direction (ascending or descending).
 * - Add multiple sort conditions for different columns.
 * - Remove existing sort conditions.
 * - Reorder sort conditions via drag-and-drop functionality.
 * - Automatically applies the sort conditions to the grid when changed.
 * - Disable already-selected columns from appearing in the dropdown for new sort conditions.
 *
 * @inputs
 * - gridColumnApi: GridApi - The grid API instance for managing columns and applying sorting.
 * 
 * @outputs
 * - This component does not emit outputs directly, but it notifies the `AgGridCommonFilterService` whenever a change is made, which can be used for saving changes externally.
 *
 * @usage
 * <app-ag-grid-common-sort
 *   [gridColumnApi]="agGrid"
 *   #agGridCommonSortRef>
 * </app-ag-grid-common-sort>
 *
 * The component uses Angular Material's drag-and-drop and selection components for a smooth user experience.
 * It listens for changes in sorting and automatically updates the grid accordingly.
 */

import { Component, Input } from '@angular/core';
import { GridApi, ColumnState } from 'ag-grid-community';
import { agGridSortEnum, defaultAgGridSortOperators, SortConditions } from '@shared/models/ag-grid-common-filter.model';
import { AgGridCommonFilterService } from '@shared/services/ag-grid-common-filter.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ColDef } from 'ag-grid-community';
@Component({
  selector: 'app-ag-grid-common-sort',
  templateUrl: './ag-grid-common-sort.component.html',
  styleUrls: ['./ag-grid-common-sort.component.scss']
})
export class AgGridCommonSortComponent {
  @Input() gridColumnApi!: GridApi;  // Reference to the grid's API for applying column states

  conditions: SortConditions[] = [];  // List of sorting conditions added by the user
  defaultAgGridSortOperators = defaultAgGridSortOperators;  // List of default sorting operators (asc/desc)
  validConditions: SortConditions[] = [];  // List of valid sorting conditions

  constructor(private agGridCommonFilterService: AgGridCommonFilterService) {
    // Initialize sorting behavior by setting each column to not be sortable by default
    setTimeout(() => {
      const columnDefs: ColDef[] = ((this.gridColumnApi as any)?.columnModel?.columnDefs || []);
      columnDefs.forEach((col: ColDef) => {
        col.sortable = false;
      });
    });
  }

  /**
   * Adds a new sorting condition. The condition uses the grid's column definitions and 
   * initializes with 'asc' sort type by default.
   */
  addCondition() {
    const cond: SortConditions = new SortConditions();
    cond.columnDefs = [...((this.gridColumnApi as any)?.columnModel?.columnDefs || [])];
    cond.sortType = agGridSortEnum.asc;
    this.conditions.push(cond);
    this.applySort();  // Trigger sorting when a new condition is added
  }

  /**
   * Removes a specific sorting condition and triggers the save event.
   * @param condition - The sorting condition to remove.
   */
  removeCondition(condition: SortConditions) {
    const index = this.conditions.indexOf(condition);
    if (index > -1) {
      this.conditions.splice(index, 1);
      this.applySort();  // Trigger sorting when a condition is removed
    }
    this.agGridCommonFilterService.saveChanges$.next(true); 
  }

  /**
   * Clears all sorting conditions.
   */
  clearFilter() {
    this.conditions.length = 0;
    this.applySort();  // Trigger sorting when all conditions are cleared
  }

  /**
   * Applies the sorting conditions to the grid by setting the column state with the valid 
   * sorting conditions. Ensures that the column ID and sort type are properly set.
   */
  applySort() {
    // Step 1: Clear all sorting states for all columns
    const allColumns = this.gridColumnApi.getColumns();
    if (allColumns) {
      const resetColumnState = allColumns.map((col) => ({
        colId: col.getColId(),
        sort: null // Clear the sort state for each column
      }));

      // Apply the reset state to remove all sorting
      this.gridColumnApi.applyColumnState({
        state: resetColumnState,
        applyOrder: true // Make sure the order is applied
      });
    }

    // Step 2: Set valid conditions for sorting
    this.SetValidConditions();

    // Step 3: Apply the new sorting conditions
    const columnState = this.validConditions.map(state => ({
      colId: state.column ?? '', // Ensure colId is set to a string
      sort: state.sortType === 'asc' || state.sortType === 'desc' ? state.sortType : null // Ensure sort is either 'asc', 'desc', or null
    }));

    // Apply the new column sorting state
    this.gridColumnApi.applyColumnState({
      state: columnState as ColumnState[], // Apply the new sorting state
      applyOrder: true // Ensure the order of the columns is applied
    });

    // Notify that changes have been saved
    this.agGridCommonFilterService.saveChanges$.next(true);
  }
  /**
   * Filters the list of conditions to keep only valid ones. A condition is valid if it has 
   * a column and a valid sort type.
   */
  SetValidConditions() {
    this.validConditions = this.conditions.filter((cond: SortConditions) => new SortConditions(cond).isValid);
  }

  /**
   * Adds an initial sorting condition with the first available column set to 'asc'.
   */
  addFirstCondition() {
    const cond: SortConditions = new SortConditions();
    cond.columnDefs = [...((this.gridColumnApi as any)?.columnModel?.columnDefs || [])];
    const firstField = cond.columnDefs.filter((con: any) => con?.field)?.[0]?.field || '';
    cond.column = firstField;
    cond.sortType = agGridSortEnum.asc;
    this.conditions.push(cond);
  }

  /**
   * Handles the reordering of sorting conditions using drag and drop. 
   * @param event - The drag-and-drop event containing the reorder data.
   */
  onReorderFromDrop(event: CdkDragDrop<string[]>) {
    const movedButton = this.conditions.splice(event.previousIndex, 1)[0];
    this.conditions.splice(event.currentIndex, 0, movedButton);
    this.applySort();
  }

  /**
   * Determines whether a column should be disabled in the dropdown selection. 
   * A column is disabled if it has already been selected in any sorting condition.
   * @param field - The column field to check.
   * @returns - True if the column is already selected, false otherwise.
   */
  isColumnDisabled(field: string): boolean {
    return this.conditions.some(cond => cond.column === field);
  }
}
