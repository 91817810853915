/**
 * @component AgGridCommonFieldsComponent
 * 
 * @description 
 * This component is responsible for managing the visibility and order of columns in an ag-Grid table.
 * It provides the ability to reorder columns via drag-and-drop, toggle column visibility, and reset the columns to their default state. 
 * The component automatically updates the grid's column state based on user interactions and tracks unsaved changes.
 * It uses Angular's CDK for drag-and-drop functionality and Material UI components for the interface.
 * 
 * @inputs 
 * - `gridColumnApi: GridApi` - The API to control ag-Grid columns, used for applying column states and fetching column definitions.
 * 
 * @outputs 
 * - This component does not emit outputs directly, but it notifies the `AgGridCommonFilterService` whenever a change is made, which can be used for saving changes externally.
 * 
 * @usage
 * Example usage:
 * ```
 * <app-ag-grid-common-fields [gridColumnApi]="gridApi"></app-ag-grid-common-fields>
 * ```
 */

import { GridApi, ColDef, ColumnState } from 'ag-grid-community';
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, Input, OnInit } from "@angular/core";
import { AgGridCommonFilterService } from "@shared/services/ag-grid-common-filter.service";

// Interface to extend ColumnState with optional ColDef reference
export interface ColumnStateData extends ColumnState {
  colRef?: ColDef; // Optional column reference for header details
}

@Component({
  selector: "app-ag-grid-common-fields",
  templateUrl: "./ag-grid-common-fields.component.html",
  styleUrls: ["./ag-grid-common-fields.component.scss"],
})
export class AgGridCommonFieldsComponent implements OnInit {
  @Input() gridColumnApi!: GridApi; // API to control grid columns

  // Stores the current state of all columns
  allColumnsState: ColumnStateData[] = [];
  // Stores the default column state (used for resetting)
  defaultColumnsState: ColumnStateData[] = [];

  // Tracks whether there are unsaved changes in column visibility or order
  hasChanges = false;

  constructor(private agGridCommonFilterService: AgGridCommonFilterService) { }

  ngOnInit(): void {
    // Fetch the current state of columns
    this.allColumnsState = this.gridColumnApi.getColumnState() || [];

    // Filter to only include columns with headers
    this.allColumnsState = this.allColumnsState.filter((el: ColumnStateData) => {
      const column = this.gridColumnApi.getColumn(el.colId);
      if (column) {
        el.colRef = column.getColDef();
        return !!el.colRef?.headerName;
      }
      return false;
    });

    // Prepare the default column state where no columns are hidden
    this.defaultColumnsState = this.allColumnsState.map((el: ColumnStateData) => {
      el.hide = false;
      return el;
    });
  }

  /**
   * Toggles the visibility of a column and applies the new column state to the grid.
   * @param col The column whose visibility is being changed.
   * @param isHide Whether the column should be hidden or shown.
   */
  changeVisibility(col: ColumnState, isHide: boolean = false) {
    col.hide = isHide;
    this.gridColumnApi.applyColumnState({
      state: this.allColumnsState,
      applyOrder: false,
    });
    this.agGridCommonFilterService.saveChanges$.next(true);
    this.checkChanges();
  }

  /**
   * Reorders columns after a drag-and-drop operation and applies the new column state to the grid.
   * @param event The drag-and-drop event containing the previous and current indices.
   */
  onReorderFromDrop(event: CdkDragDrop<string[]>) {
    const movedButton = this.allColumnsState.splice(event.previousIndex, 1)[0];
    this.allColumnsState.splice(event.currentIndex, 0, movedButton);
    this.gridColumnApi.applyColumnState({
      state: this.allColumnsState,
      applyOrder: true,
    });
    this.agGridCommonFilterService.saveChanges$.next(true);
    this.checkChanges();
  }

  /**
   * Applies column state after a checkbox is toggled to show/hide a column.
   */
  onCheckboxChanged(event: any) {
    this.gridColumnApi.applyColumnState({
      state: this.allColumnsState,
      applyOrder: false,
    });
    this.agGridCommonFilterService.saveChanges$.next(true);
    this.checkChanges();
  }

  dragleave(e: any) { }

  /**
   * Resets all columns to their default state, including order and visibility.
   */
  reset() {
    this.allColumnsState = [...this.defaultColumnsState];
    this.gridColumnApi.applyColumnState({
      state: this.defaultColumnsState,
      applyOrder: true,
    });
    this.checkChanges();
  }

  /**
   * Checks whether there are any unsaved changes in column order or visibility.
   */
  checkChanges() {
    const hiddenFields: ColumnStateData[] = this.allColumnsState.filter((el: ColumnStateData) => el.hide);
    const defaultOrderIds = this.defaultColumnsState.map((el: ColumnStateData) => el.colId);
    const currentOrderIds = this.allColumnsState.map((el: ColumnStateData) => el.colId);
    const hasOrderChanges = JSON.stringify(defaultOrderIds) !== JSON.stringify(currentOrderIds);

    // Determine if there are any unsaved changes
    this.hasChanges = hiddenFields.length > 0 || hasOrderChanges;
  }
}
