import { ColDef } from 'ag-grid-community';
import { GetAgFilterColumnPipe } from "@shared/pipes/get-ag-filter-column.pipe";
import { groupBy } from "lodash";

export enum agGridFilterTypes {
  date = "agDateColumnFilter",
  text = "agTextColumnFilter",
  number = "agNumberColumnFilter"
}

export enum agGridCondFilterType {
  date = "date",
  text = "text",
  number = "number"
}

export enum agGridOperatorsEnum {
  inRange = "inRange",
  contains = "contains",
  equals = "equals",
  blank = "blank",
  notBlank = "notBlank"
}

export const defaultAgGridOperators = [
  { value: "gridFilter.contains", code: "contains" },
  { value: "gridFilter.does_not_contain", code: "notContains" },
  { value: "gridFilter.is", code: "equals" },
  { value: "gridFilter.is_not", code: "notEqual" },
  { value: "gridFilter.starts_with", code: "startsWith" },
  { value: "gridFilter.ends_with", code: "endsWith" },
  { value: "gridFilter.is_empty", code: "blank" },
  { value: "gridFilter.is_not_empty", code: "notBlank" }
];

export const numberAgGridOperators = [
  { value: "gridFilter.equals", code: "equals" },
  { value: "gridFilter.not_equal", code: "notEqual" },
  { value: "gridFilter.greater_than", code: "greaterThan" },
  { value: "gridFilter.greater_than_or_equal", code: "greaterThanOrEqual" },
  { value: "gridFilter.lesser_than", code: "lessThan" },
  { value: "gridFilter.lesser_than_or_equal", code: "lessThanOrEqual" },
  { value: "gridFilter.between", code: "inRange" },
  { value: "gridFilter.is_empty", code: "blank" },
  { value: "gridFilter.is_not_empty", code: "notBlank" },
];

export const dateAgGridOperators = [
  { value: "gridFilter.equals", code: "equals" },
  { value: "gridFilter.not_equal", code: "notEqual" },
  { value: "gridFilter.lesser_than", code: "lessThan" },
  { value: "gridFilter.greater_than", code: "greaterThan" },
  { value: "gridFilter.between", code: "inRange" },
  { value: "gridFilter.is_empty", code: "blank" },
  { value: "gridFilter.is_not_empty", code: "notBlank" }
];
export const OperatorsWithoutInput = [
  "blank",
  "notBlank"
];


export class FilterConditions {
  column: string | null = null;
  filterType: string | null = null;
  type: string | null = null;
  filter: any = null;
  filterTo:any = null;
  operator: string | null = null;
  columnDefs: ColDef[] = [];

  constructor(data?: Partial<FilterConditions>) {
    Object.assign(this, data);
  }

  get isValid(): boolean {
    return !!this.column && !!this.type && this.isValidFilterInput;
  }

  get isValidFilterInput(): boolean {
    return OperatorsWithoutInput.includes(this?.type || '') || !!this.filter;
  }
}

export const updateFilterType = (
  cond: FilterConditions,
  field: string,
  columnDefs: ColDef[]
) => {
  
  const columnRef = new GetAgFilterColumnPipe().transform(columnDefs, field);
  console.log(columnRef?.filter)
  cond.filterType = columnRef?.filter === agGridFilterTypes.date
    ? agGridCondFilterType.date
    : agGridCondFilterType.text;
};

export const formatDateForAgGrid = (val: any): string | null => {
  if (val) {
    const date = new Date(val);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day} 00:00:00`;
  }
  return null;
};

interface FilterConditionData {
  filterType: string;
  operator?: string;
  filter?: string | number | null;
  filterTo?: string | number | null;
  type?: string;
  dateFrom?: string | null;
  dateTo?: string | null;
  condition1?: FilterConditionData;
  condition2?: FilterConditionData;
  conditions?: FilterConditionData[]; // New structure for an array of conditions
}

export const getAgGridFilterStructure = (
  arrFilter: FilterConditions[] = [],
  operator: 'AND' | 'OR' = 'AND'
): { [key: string]: FilterConditionData } => {
  const dataFilter: { [key: string]: FilterConditionData } = {};

  Object.entries(groupBy(arrFilter, "column")).forEach(
    ([filterElementKey, filterElementValue]: [string, FilterConditions[]]) => {
      const filterElementConditions: FilterConditions[] = filterElementValue || [];

      // Initializing conditions array
      const conditionsArray: FilterConditionData[] = [];

      filterElementConditions.forEach((cond: FilterConditions, i: number) => {
        const conditionData: FilterConditionData = {
          filterType: cond.filterType || '',
          type: cond.type || '',
          filter: cond.filter,
        };

        if (cond.type === agGridOperatorsEnum.inRange) {
          conditionData.filter = cond.filter;
          conditionData.filterTo = cond.filterTo
        } else {
          conditionData.filter = cond.filter;
        }
        if (cond.filterType === agGridCondFilterType.date) {
          if (cond.type === agGridOperatorsEnum.inRange) {
            conditionData.dateFrom = cond.filter;
            conditionData.dateTo = cond.filterTo;
          } else {
            conditionData.dateFrom = cond.filter;
            conditionData.dateTo = null;
          }
        }
        conditionsArray.push(conditionData);

        if (!dataFilter[filterElementKey]) {
          dataFilter[filterElementKey] = {
            filterType: cond.filterType || '',
            operator: operator,
            conditions: [] // Initialize as an empty array for conditions
          };
        }

        // Add first two conditions into condition1 and condition2
        if (i === 0) {
          dataFilter[filterElementKey].condition1 = conditionData;
        } else if (i === 1) {
          dataFilter[filterElementKey].condition2 = conditionData;
        }
      });

      // Assign the conditions array
      dataFilter[filterElementKey].conditions = conditionsArray;
    }
  );

  return dataFilter;
};