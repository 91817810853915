/**
 * @component AgGridHeaderComponent
 * 
 * @description 
 * This component acts as the header section for an ag-Grid table, incorporating filters, sorting, view management, and column visibility controls.
 * It uses various child components to manage these functionalities, such as filtering and sorting of grid data, saving and applying view states, 
 * and toggling the visibility of columns. It allows users to interact with the grid through filters, sorting options, and visibility toggles, 
 * all managed via the Angular Material UI and ag-Grid APIs.
 * 
 * @inputs 
 * - `agGrid: GridApi` - The API to control the ag-Grid table, used for setting filter models, applying column state, and other grid actions.
 * - `currentPageName: FilterViewPageNames | null` - The current page name, used to manage view-specific settings such as saved views.
 * 
 * @usage 
 * This component should be placed above an ag-Grid instance to serve as the grid's header. It provides a user-friendly interface for managing 
 * the grid's filtering, sorting, and column visibility features. It can be combined with other ag-Grid components to fully control the behavior of the grid.
 * 
 * Example usage:
 * ```
 * <app-ag-grid-header [agGrid]="gridApi" [currentPageName]="pageName"></app-ag-grid-header>
 * ```
 */
import { Component, Input, ViewChild } from '@angular/core';
import { FilterViewPageNames } from '@shared/models/ag-grid-common-filter.model';
import { GridApi } from 'ag-grid-community';
import { AgGridCommonFieldsComponent } from '../ag-grid-common-fields/ag-grid-common-fields.component';
import { AgGridCommonFilterComponent } from '../ag-grid-common-filter/ag-grid-common-filter.component';
import { AgGridCommonSortComponent } from '../ag-grid-common-sort/ag-grid-common-sort.component';
import { AgGridCommonFilterViewComponent } from '../ag-grid-common-filter-view/ag-grid-common-filter-view.component';
@Component({
  selector: 'app-ag-grid-header',
  templateUrl: './ag-grid-header.component.html',
  styleUrls: ['./ag-grid-header.component.scss']
})
export class AgGridHeaderComponent {

  /**
   * The GridApi instance from AG-Grid which is passed from the parent component.
   * This allows the AgGridHeaderComponent to interact with the AG-Grid instance.
   */
  @Input() agGrid!: GridApi;

  /**
   * The current page name that will be used for managing saved views (filter, sort, columns).
   * This helps uniquely identify the grid for saving and loading different views.
   */
  @Input() currentPageName: FilterViewPageNames | null = null;

  /**
   * Reference to the AgGridCommonFilterComponent which handles filtering operations for the grid.
   */
  @ViewChild('filterComponentRef') filterComponentRef!: AgGridCommonFilterComponent;

  /**
   * Reference to the AgGridCommonSortComponent which manages sorting operations for the grid.
   */
  @ViewChild('agGridCommonSortRef') agGridCommonSortRef!: AgGridCommonSortComponent;

  /**
   * Reference to the AgGridCommonFieldsComponent which handles the visibility and arrangement of columns in the grid.
   */
  @ViewChild('aGridCommonFieldsRef') aGridCommonFieldsRef!: AgGridCommonFieldsComponent;

  /**
   * Reference to the AgGridCommonFilterViewComponent which is responsible for saving, updating, and switching views for the grid.
   */
  @ViewChild('agGridCommonFilterViewRef') agGridCommonFilterViewRef!: AgGridCommonFilterViewComponent;

  constructor() { }

}
